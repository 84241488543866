import React from "react";
import styled from "styled-components";
import { contactEmail } from "../lib/config";
import { Link } from "gatsby";

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 15rem;
  padding: 1rem 0 3rem;
  font-size: 1.6rem;

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-right: 1rem;
    }
  }

  a {
    font-weight: 700;
    text-decoration: none;
    color: initial;
    transition: color 0.3s ease;
    color: ${({ theme }) => theme.primary};
  }
`;

const Footer = () => (
  <FooterContainer>
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <a href="https://github.com/tomalexhughes">GitHub</a>
      </li>
      <li>
        <a href={`mailto:${contactEmail}`}>Contact</a>
      </li>
    </ul>

    <span>© {new Date().getFullYear()}</span>
  </FooterContainer>
);

export default Footer;
