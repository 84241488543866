import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

const HeaderContainer = styled.header`
  padding-top: 1rem;
  text-transform: lowercase;
  font-weight: 700;
  margin-bottom: 5rem;

  h1 {
    font-size: 2rem;
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.bodyText};
    text-decoration: none;
  }
`;

const Header = ({ siteTitle }) => (
  <HeaderContainer>
    <h1>
      <Link to="/">{siteTitle}</Link>
    </h1>
  </HeaderContainer>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
