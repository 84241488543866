import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    html { font-size: 10px; }

    body {
        margin: 0 auto;
        max-width: 960px;
        padding: 0 1rem;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.6rem;
        color: ${({ theme }) => theme.bodyText}
    }
`;

export default GlobalStyle;
